export const COMPANY_NAME = "Engine";
export const COMPANY_NAME_POSSESSIVE = "Engine's";
export const COMPANY_LOGO = "engine--lockup";
export const COMPANY_URL = "engine.com";
export const COMPANY_BRANDMARK = "engine";
export const COMPANY_ROUTES = {
  base: `https://www.${COMPANY_URL}`,
  faqs: `https://www.${COMPANY_URL}/faqs`,
  privacy: `https://www.${COMPANY_URL}/privacy-policy`,
  socialResponsibility: `https://www.${COMPANY_URL}/social-responsibility`,
  terms: `https://www.${COMPANY_URL}/terms-of-service`,
  termsGroups: `https://www.${COMPANY_URL}/terms-of-service-groups`,
};
export const COMPANY_SUPPORT_EMAIL = `support@${COMPANY_URL}`;
export const GROUPS_SUPPORT_EMAIL = `groups@${COMPANY_URL}`;
