/**
 * @deprecated This hook is deprecated and should not be used. Use src/@hotel-engine/constants/companyNames.ts instead.
 */
export const useCompanyName = () => {
  const COMPANY_NAME = "Engine" as const;
  const COMPANY_NAME_POSSESSIVE = "Engine's" as const;
  const COMPANY_NAME_CONDENSED = "Engine" as const;
  const COMPANY_ABBREVIATION = "Engine" as const;
  const COMPANY_URL = "engine.com" as const;
  const COMPANY_LOGO = "engine--lockup" as const;
  const COMPANY_BRANDMARK = "engine" as const;
  const companySocials = {
    twitter: "https://x.com/gowithengine",
    linkedin: "https://www.linkedin.com/company/engine",
    facebook: "https://www.facebook.com/gowithengine",
    instagram: "https://www.instagram.com/gowithengine",
  };

  return {
    COMPANY_NAME,
    COMPANY_NAME_CONDENSED,
    COMPANY_NAME_POSSESSIVE: COMPANY_NAME_POSSESSIVE,
    COMPANY_ABBREVIATION,
    COMPANY_URL,
    COMPANY_LOGO,
    COMPANY_BRANDMARK,
    companySocials,
  };
};
