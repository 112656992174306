import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import * as Styled from "./styles";

interface IReviewCompleteButtonProps {
  dismissModal: () => void;
  referrer: string;
}
export const NotificationCompleteButton = ({
  dismissModal,
  referrer,
}: IReviewCompleteButtonProps) => {
  return (
    <Styled.CloseReviewButton
      data-testid="close-action-button"
      onClick={dismissModal}
      type="primary"
    >
      {referrer !== "page" ? "Close" : `Go to ${COMPANY_NAME}`}
    </Styled.CloseReviewButton>
  );
};
