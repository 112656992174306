import { COMPANY_NAME, COMPANY_ROUTES } from "@hotel-engine/constants/companyNames";
import * as Styled from "./styles";

export const PrivacyPolicyDisclaimer = ({
  $top,
  $right,
  $bottom,
  $left,
}: Styled.IPrivacyPolicyDisclaimer) => {
  return (
    <Styled.PrivacyPolicyDisclaimer $top={$top} $right={$right} $bottom={$bottom} $left={$left}>
      <p>
        See how {COMPANY_NAME} uses your data in our
        <a href={COMPANY_ROUTES.privacy} target="_blank" rel="noreferrer">
          Privacy Policy.
        </a>
      </p>
    </Styled.PrivacyPolicyDisclaimer>
  );
};
