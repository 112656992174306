import type { TimeLineItemProps } from "@hotel-engine/common/Timeline";
import * as Styled from "./styles";
import type { ISupportCase } from "@hotel-engine/types/supportCase";
import moment from "moment-timezone";
import { Box, Typography } from "@hotelengine/atlas-web";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";

interface IActivityDetails {
  supportCase: ISupportCase | undefined;
}

const ActivityDetails = ({ supportCase }: IActivityDetails) => {
  const statusClosedAt = supportCase?.statusClosedAt;
  const statusInProgressAt = supportCase?.statusInProgressAt;
  const submittedBy = supportCase?.submittedBy;
  const createdAt = supportCase?.createdAt;
  const timeLineItems: TimeLineItemProps[] = [];
  const { tokens } = useGlobalTheme();

  if (!!statusClosedAt) {
    const timeFormat = moment(statusClosedAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem: TimeLineItemProps = {
      dot: <Styled.TimeLineIconFill color={tokens.colors.foregroundPrimary} />,
      children: (
        <Box display="flex" flexDirection="column">
          <Typography variant="body/sm" color="foregroundPrimary">
            Case closed by {COMPANY_NAME}
          </Typography>
          <Typography variant="body/xs">{timeFormat}</Typography>
        </Box>
      ),
    };
    timeLineItems.push(timeLineItem);
  }

  if (!!statusInProgressAt) {
    const timeFormat = moment(statusInProgressAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem: TimeLineItemProps = {
      dot: <Styled.TimeLineIconNoFill color={tokens.colors.foregroundPrimary} />,
      children: (
        <Box display="flex" flexDirection="column">
          <Typography variant="body/sm" color="foregroundPrimary">
            Case in Progress
          </Typography>
          <Typography variant="body/xs">{timeFormat}</Typography>
        </Box>
      ),
    };
    timeLineItems.push(timeLineItem);
  }

  if (!!submittedBy) {
    const timeFormat = moment(createdAt).tz("America/New_York").format("M/D/YYYY h:mmA z");
    const timeLineItem: TimeLineItemProps = {
      dot: <Styled.TimeLineIconNoFill color={tokens.colors.foregroundPrimary} />,
      children: (
        <Box display="flex" flexDirection="column">
          <Typography variant="body/sm" color="foregroundPrimary">
            Case submitted by {submittedBy}
          </Typography>
          <Typography variant="body/xs">{timeFormat}</Typography>
        </Box>
      ),
    };
    timeLineItems.push(timeLineItem);
  }

  return (
    <Box>
      <Typography variant="heading/md">Activity</Typography>

      <Styled.ActivityTimeLine>
        {timeLineItems.map((item, idx) => (
          <Styled.ActivityTimeLine.Item key={idx} {...item} />
        ))}
      </Styled.ActivityTimeLine>
    </Box>
  );
};

export default ActivityDetails;
